<template>
  <div
    v-if="
      getUser.role.slug === 'charge-de-clientele' ||
        getUser.role.slug === 'super-administrateur' ||
        getUser.role.slug === 'responsable-relation-client'
    "
  >
  <b-breadcrumb class="mb-2">
        <b-breadcrumb-item to="/orders/punctual">Commandes</b-breadcrumb-item>        
        <b-breadcrumb-item active>Détails</b-breadcrumb-item>
  </b-breadcrumb>
    <div
      v-if="isPageLoading"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 30vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem"
        class="mr-1"
      />
    </div>
    <div
      v-else-if="isError"
      class="d-flex flex-column justify-content-center mt-5 text-center"
      style="padding-top: 30vh"
    >
      <h1 class="text-danger">
        <feather-icon
          icon="XCircleIcon"
          size="100"
        />
      </h1>
      <p class="font-medium-1 text-danger font-weight-normal">
        Aucune commande ne correspond à cet identifiant
      </p>
    </div>
    <div v-else>
      <template>
        <!-- First Row -->
        <b-row>
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <b-card>
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <div
                  class="
                    d-flex
                    justify-content-between
                    flex-md-row flex-column
                    invoice-spacing
                    mt-0
                  "
                >
                  <!-- Header: Left Content -->
                  <div>
                    <div class="logo-wrapper">
                      <b-avatar
                        :text="
                          avatarText(
                            `${order.user.first_name} ` +
                              ` ${order.user.last_name}`
                          )
                        "
                        :src="order.user.profile_image"
                        size="104px"
                        rounded
                      />
                      <h3 class="mt-2 mb-25 text-success">
                        {{ order.user.full_name }}
                      </h3>
                    </div>
                  </div>

                  <!-- Header: Right Content -->
                  <div class="mt-md-0 mt-2">
                    <h4 class="invoice-title mb-3">
                      Commande
                      <span
                        class="invoice-number text-success"
                      >#{{ order.id }}</span>
                      <b-badge
                        pill
                        :variant="`light-${resolveOrderStatusVariant(
                          order.status, order.type_of_payment
                        )}`"
                        class="text-capitalize font-medium-3 ml-1"
                      >
                        {{ resolveStatusText(order.status, order.type_of_payment) }}
                      </b-badge>
                      <span v-if="order.type_of_payment == 1 && order.status != 3">Reste: {{ order.remaining_order_price }} FCFA</span>
                    </h4>
                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title">
                        Adresse:
                        <small class="font-weight-bold text-info font-small-4">
                          {{ order.address }}</small>
                      </p>
                    </div>
                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title">
                        Email:
                        <small class="font-weight-bold text-info font-small-4">
                          {{ order.user.email }}</small>
                      </p>
                    </div>
                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title">
                        Téléphone:
                        <small class="font-weight-bold text-info font-small-4">
                          {{ order.user.phone_number }}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-card-body class="pt-0">
                <h6 class="mb-2 text-center font-medium-2">
                  Détails de la commande
                </h6>
                <b-row class="">
                  <!-- Col: Invoice To -->
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <p class="card-text mb-25 font-medium-1">
                      Budget:
                      <span class="text-info"> {{ order.budget }} FCFA </span>
                    </p>
                    <p class="card-text mb-25 font-medium-1">
                      Service demandé:
                      <span class="text-info">{{ order.service.name }}</span>
                    </p>
                    <p class="card-text mb-25 font-medium-1">
                      Date de la demande:
                      <span class="text-info">
                        {{ moment(order.created_at).locale("fr").format("ll") }}
                      </span>
                    </p>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <p class="card-text mb-25 font-medium-1">
                      Date de livraison:
                      <span class="text-info">
                        {{
                          moment(order.desired_date).locale("fr").format("LL")
                        }}
                      </span>
                    </p>
                    <p class="card-text mb-25 font-medium-1">
                      Heure de livraison:
                      <span class="text-info">
                        {{ order.hour.replace(":00+00", "") }}
                      </span>
                    </p>
                  </b-col>
                  <!-- Col: Payment Details -->
                </b-row>
              </b-card-body>

              <!-- Invoice Description: Table -->
              <b-table-lite
                responsive
                :items="[{ description: order.description }]"
                :fields="[
                  {
                    key: 'description',
                    label: 'Description de la commande du client',
                    class: 'text-center font-medium-2',
                  },
                ]"
              >
                <template #cell(description)="data">
                  <b-card-text
                    class="font-weight-bold mb-25 font-medium-1 text-info"
                  >
                    {{
                      order.description != null
                        ? order.description
                        : "Ce(te) client(e) n'a fait aucune description particulière."
                    }}
                  </b-card-text>
                </template>
              </b-table-lite>
            </b-card>
            <works-picture
              :service-name="serviceName"
              :default-value="defaultValue"
              :pictures="pictures"
              :is-null="isnull"
              :client-name="clientName"
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions"
          >
            <b-card>
              <b-button
                :disabled="order.status !== 0 && order.status !== 1"
                variant="primary"
                block
                class="mb-75"
                @click="makeOffer(order)"
              >
                <span class="text-nowrap">Faire une offre</span>
              </b-button>
              <b-button
                v-b-modal.modal-offers-professionals
                variant="outline-info"
                block
                class="mb-75 text-white"
                @click="applyGetOrderOffersAction()"
              >
                <span class="text-nowrap">Offres effectuées</span>
              </b-button>
              <b-button
                :disabled="order.status != 2 || order.status == 2 && order.type_of_payment == 1"
                variant="success"
                block
                class="mb-75 text-white"
                @click="applyMakeOrderAsFinished()"
              >
                Terminer la commande
              </b-button>
              <b-button
                v-if="getUser.role.slug === 'super-administrateur'"
                :disabled="order.status !== 0 && order.status !== 1"
                variant="outline-danger"
                block
                class="mb-75"
                @click="applyDeleteOrderAction()"
              >
                Supprimer la commande
              </b-button>
              <b-button
                variant="primary"
                block
                class="mb-75"
                @click="
                  showWorksPicture(
                    order.service.name,order.user.full_name,
                    JSON.parse(order.pictures)
                  )
                "
              >
                <span class="text-wrap">Images de la commande</span>
              </b-button>
            </b-card>
          </b-col>
          <div>
            <b-modal
              id="modal-new-offer"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              :title="`Offre pour la commande de ${order.service.name}`"
            >
              <validation-observer
                #default="{}"
                ref="newOfferForm"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  autocomplete="off"
                  @reset.prevent="hideModal"
                  @submit.prevent="applyMakeOfferAction"
                >
                  <b-form-group
                    :label="`Professionnel fournissant le service de ${order.service.name} `"
                    label-for="role"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="pro"
                      rules="required"
                    >
                      <b-form-select
                        v-if="proOptions.length == 1 && proOptions[0].disabled"
                        id="professional_id"
                        v-model="payload.professional_id"
                        :options="proOptions"
                      />
                      <v-select
                        v-else
                        id="role"
                        v-model="payload.professional_id"
                        :reduce="(professional) => professional.id"
                        label="full_name"
                        :options="proOptions"
                      >
                        <span
                          slot="no-options"
                          @click="$refs.select.open = false"
                        >
                          Chargement...
                        </span>
                        <template
                          #option="{ full_name, address, phone_number }"
                        >
                         
                          <span>
                            {{ full_name }}
                          </span>
                          &nbsp;
                          <span class="text-info">
                            {{ address }}
                          </span>
                          &nbsp;
                          <span class="text-success">+{{ phone_number }}</span>
                        </template>
                      </v-select>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="prix"
                    rules="required"
                  >
                    <b-form-group
                      label="Prix"
                      label-for="designation"
                    >
                      <b-form-input
                        id="price"
                        v-model="payload.price"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      :disabled="isMakingOfferLoading"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      <div v-if="isMakingOfferLoading">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
                      <span v-else>Envoyer</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Annuler
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-modal>
            <b-modal
              id="modal-offers-professionals"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              title="Offres effectuées"
            >
              <b-table
                ref="refInvoiceListTable"
                :items="offers"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :busy="isOffersLoading"
                size="xl"
                show-empty
                empty-text="Liste vide"
                class="position-relative"
                
              >
                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle" /> &nbsp;
                    <strong>Chargement...</strong>
                  </div>
                </template>
                <!-- Column: Invoice Status -->
                <template #cell(is_rejected)="data">
                  <b-badge
                    pill
                    :variant="`light-${resolveOfferStatusVariant(
                      data.item
                    )}`"
                    class="text-capitalize font-small-3"
                  >
                    {{
                      order.accepted_offer_id != null &&
                        data.item.id == order.accepted_offer_id 
                        ? "Offre acceptée"
                        :data.item.is_rejected ? "Offre rejeté" : "--------"
                    }}
                  </b-badge>
                </template>

                <!-- Column: Prix -->
                <template #cell(reason)="data">
                  <span class="text-info">{{ data.item.rejection_raison !== null ? data.item.rejection_raison : "-------"}}</span>
                </template>

                 <!-- Column: Prix -->
                 <template #cell(price)="data">
                  <span >{{ data.item.price}} FCFA</span>
                </template>


                <!-- Column: professional -->
                <template #cell(professional)="data">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="32"
                        :src="data.item.professional.profile_image"
                      />
                    </template>
                    <span class="font-weight-bold d-block text-nowrap">
                      {{ data.item.professional.full_name }}
                    </span>
                    <small class="text-success font-small-3">{{
                      data.item.professional.phone_number
                    }}</small>
                  </b-media>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data" >
                  <feather-icon
                  v-b-modal.modal-action
                          icon="MoreVerticalIcon"
                          class="text-info"
                          size="20"
                          @click="getInfos(data.item, order)"
                        />
                  
                 <!--  <div class="text-nowrap">
                    
                    <b-dropdown
                      variant="link"
                      toggle-class="p-0"
                      no-caret
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="20"
                          class="align-middle text-center text-info"
                        />
                      </template>
                      <b-dropdown-item
                        :id="`edit${data.item.id}`"
                        class="cursor-pointer itemActions"
                        @click="getOffer(data.item)"
                      >
                        <feather-icon
                          icon="EditIcon"
                          class="text-info"
                          size="20"
                        />
                        <span
                          class="align-middle ml-50"
                        >Modifier</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        :disabled="order.status >= 2"
                        class="cursor-pointer itemActions"
                        @click="applyDeleteOrderOfferAction(data.item.id)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          class="text-danger"
                          size="20"
                        />
                        <span class="align-middle ml-50">Supprimer</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        :disabled="order.status >= 2"
                        class="cursor-pointer itemActions"
                        @click="acceptOfferForCustomer(data.item.id)"
                      >
                        <feather-icon
                          icon="LinkIcon"
                          class="text-success"
                          size="20"
                        />
                        <span
                          class="align-middle ml-50"
                        >Régulariser le paiement de cette offre</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div> -->
                </template>
              </b-table>
            </b-modal>

          </div>
        </b-row>
        <!-- MODAL ACTION -->
        <b-modal
          id="modal-action"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Actions sur l'offre"
          size="sm" 
        >
          <b-list-group>
            <b-list-group-item button :disabled="orderItem.accepted_offer_id != null &&
                        offer.id == orderItem.accepted_offer_id" @click="getOffer(offer)"><feather-icon
                          icon="EditIcon"
                          class="text-info"
                          size="20"
                          
                        />
                        <span
                          class="align-middle ml-50"
                        >Modifier</span></b-list-group-item>

            <b-list-group-item button :disabled="orderItem.accepted_offer_id != null &&
                        offer.id == orderItem.accepted_offer_id" @click="applyDeleteOrderOfferAction(offer.id)"> <feather-icon
                          icon="TrashIcon"
                          class="text-danger"
                          size="20"
                          
                        />
                        <span class="align-middle ml-50">Supprimer</span></b-list-group-item>
            <b-list-group-item button 
                        class="cursor-pointer itemActions"
                        :disabled="orderItem.status == 3"
                        @click="acceptOfferForCustomer(offer.id)"><feather-icon
                          icon="LinkIcon"
                          class="text-success"
                          size="20"
                        />
                        <span
                          class="align-middle ml-50"
                        >Régulariser le paiement de cette offre</span></b-list-group-item>
                        <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur'"
                        :disabled="orderItem.status == 3"
                        class="cursor-pointer itemActions"
                        @click="confimOfferPayment(offer.id)">
                        <feather-icon
                          icon="CheckIcon"
                          class="text-success"
                          size="20"
                        />
                        <span
                          class="align-middle ml-50"
                        >Confirmer le paiement de cette offre</span></b-list-group-item>
            
          </b-list-group>
            
        </b-modal>
        <b-modal
          id="modal-modify-offer"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Modification d'offre"
        >
          <validation-observer
            #default="{}"
            ref="modifyOfferForm"
          >
            <!-- Form -->
            <b-form
              class="p-2"
              autocomplete="off"
              @reset.prevent="hideModalUpdateOffer"
              @submit.prevent="applyModifyOfferAction()"
            >
              <b-form-group
                :label="`Professionnel fournissant le service de ${order.service.name} `"
                label-for="role"
              >
                <validation-provider
                  #default="{ errors }"
                  name="pro"
                  rules="required"
                >
                  <b-form-select
                    v-if="proOptions.length == 1 && proOptions[0].disabled"
                    id="professional_id"
                    v-model="updatePayload.professional_id"
                    :options="proOptions"
                  />
                  <v-select
                    v-else
                    id="role"
                    v-model="updatePayload.professional_id"
                    :reduce="(professional) => professional.id"
                    label="full_name"
                    :options="proOptions"
                  >
                    <span
                      slot="no-options"
                      @click="$refs.select.open = false"
                    >
                      Chargement...
                    </span>
                    <template
                      #option="{ full_name, address, phone_number }"
                    >
                      <span class="text-info">
                        {{ address }}
                      </span>
                          &nbsp;
                      <span>
                        {{ full_name }}
                      </span>
                          &nbsp;
                      <span class="text-success">+{{ phone_number }}</span>
                    </template>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <validation-provider
                #default="{ errors }"
                name="prix"
                rules="required"
              >
                <b-form-group
                  label="Prix"
                  label-for="designation"
                >
                  <b-form-input
                    id="price"
                    v-model="updatePayload.price"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    trim
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  <div v-if="isUpdateOfferLoading">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span v-else>Modifier</span>
                </b-button>
                <b-button
                  type="reset"
                  variant="outline-secondary"
                >
                  Annuler
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>

        <b-modal id="modal-confirm-payment" no-close-on-backdrop
          no-close-on-esc hide-footer title="Confimer le paiement">
          <div class="d-block text-center">
            <h3>Confirmation du paiement de l'offre</h3>
          </div>
          <b-button class="mt-3" variant="outline-success" block @click="confirmFullPayment">Confirmer la totalité</b-button>
          <b-button v-b-modal.modal-advance-payment class="mt-2" variant="outline-info" block @click="confirmAdvancePayment">Confirmer une avance</b-button>
        </b-modal>

      <!-- Sending advance Modal --> 
      <b-modal
          id="modal-advance-payment"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Confirmation de l'avance de l'offre"
        >
        
         <validation-observer #default="{}" ref="orderAssignmentForm">
           
            <b-form
              class="p-2"
              autocomplete="off"
              @reset.prevent="hideModalConfirmAdvance()"
              @submit.prevent="applyConfirmAdvancePaymentAction()"
            >
              <b-form-group
                label="Entrez le montant de l'avance"
                label-for="advance-amount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="avance"
                  rules="required"
                >
                  <b-form-input
                    id="advance-amount"
                    v-model="advance_amount"
                    :state="errors.length > 0 ? false : null"
                    placeholder=""
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              
              <div class="d-flex mt-2">
                <b-button
                  :disabled="isadvanceConfirmLoading"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  <div v-if="isadvanceConfirmLoading">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span v-else>Soumettre</span>
                </b-button>
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  @click="hideModalConfirmAdvance()"
                >
                  Annuler
                </b-button>
              </div>
            </b-form>
          </validation-observer> 
        </b-modal>

   
   </template>
    </div>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BAlert,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BForm,
  BFormGroup,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BCardHeader,
  BFormSelect,
  BSpinner,
  BCardBody,
  BTableLite,
  BListGroup,
  BListGroupItem,
  BBreadcrumb,
  BBreadcrumbItem
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import WorksPicture from '../../work-providers/professionals/WorksPicture.vue'
import NotAuthorized from '@/layouts/components/NotAuthorized.vue'

export default {
  components: {
    BCard,
    WorksPicture,
    BFormSelect,
    BSpinner,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCardHeader,
    BCardBody,
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BTableLite,
    BListGroup,
    BListGroupItem,
    BBreadcrumb,
    BBreadcrumbItem,
    vSelect,
    NotAuthorized,
  },

  data() {
    return {
      isadvanceConfirmLoading: false,
      orderItem: "",
      offer: "",
      order: null,
      isError: false,
      serviceName: '',
      updatePayload: {
        price: '',
        professional_id: '',
      },
      clientName: '',
      isnull: "Ce client n'a fourni aucune photo pour sa commande",
      defaultValue: 'Cliquez sur le bouton Images de la commande',
      pictures: -1,
      required,
      offers: [],
      proOptions: [],
      alphaNum,
      isMakingOfferLoading: false,
      isOffersLoading: false,
      payload: {
        price: '',
        description: '',
        professional_id: '',
      },
      tableColumns: [
        { key: 'professional', label: 'Professionel', sortable: true },
        { key: 'price', label: 'Prix', sortable: true },
        { key: 'reason', label: 'Raison du rejet', sortable: true },
        { key: 'is_rejected', label: 'Statut', sortable: true },
        { key: 'actions' },
      ],
      avatarText,
      isPageLoading: true,
      currentOffer: {},
      isUpdateOfferLoading: false,
      offerItem: '',
      advance_amount : ""
      
    }
  },

  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('orders', ['getOrder']),
  },

  watch: {},

  created() {
    console.log(this.getOrder)
    if (this.getOrder !== null) {
      this.isPageLoading = false
      this.order = this.getOrder
      console.log('ORDER', this.order)
      this.applyGetServiceProfessionalsAction()
      this.applyGetOrderOffersAction()
    } else {
      this.getOrderAction(this.$route.params.id)
        .then(response => {
          this.isPageLoading = false
          this.order = response.data
          console.log('GETORDER',this.order)
          this.applyGetServiceProfessionalsAction()
          // this.applyGetOrderOffersAction()
          
        })
        .catch(error => {
          console.log(error)
          this.isPageLoading = false
          this.isError = true
        })
        
    }
  },

  methods: {
    ...mapActions('services', ['getServiceProfessionalsAction']),
    ...mapActions(['getS3FileTemporaryUrlAction']),
    ...mapActions('orders', [
      'makeOfferAction',
      'getOrderAction',
      'getOrdersAction',
      'deleteOrderAction',
      'deleteOrderOfferAction',
      'finishOrderAction',
      'getOrderOffersAction',
      'modifyOfferAction',
      'confirmOfferPaymentAction'
    ]),
   

    makeOffer(offer) {
      this.userPackage = JSON.parse(localStorage.getItem("clientPackage"));
      this.$router.push(
        `/order/punctual/make/${offer.id}/offer?serviceId=${offer.service_id}`
      );
    },

    applyGetOrders(){
      this.isPageLoading = true
      this.getOrderAction(this.$route.params.id)
        .then(response => {
          this.isPageLoading = false
          this.order = response.data
          console.log('GETORDER',this.order)
          this.applyGetServiceProfessionalsAction()
          // this.applyGetOrderOffersAction()
          
        })
        .catch(error => {

          this.isPageLoading = false
          this.isError = true
        })
    },

    

    getOffer(offer) {
      this.$bvModal.show('modal-modify-offer')
      this.updatePayload.price = offer.price
      this.updatePayload.professional_id = offer.professional_id
      this.currentOffer = offer
    },
    getInfos(item, itemOrder) {
      this.offer = item
      this.orderItem = itemOrder
    },

    applyModifyOfferAction() {
      this.$refs.modifyOfferForm.validate().then(success => {
        if (success) {
          this.isUpdateOfferLoading = true
          this.modifyOfferAction({ updatePayload: this.updatePayload, offerId: this.currentOffer.id }).then(() => {
              this.getOrderAction(this.order.id).then(async response => {
              this.order = response.data
              this.isUpdateOfferLoading = false
              await this.applyGetOrderOffersAction()
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: 'Offre modifié avec succès',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.hideModalUpdateOffer()
              this.$bvModal.hide('modal-action')
            })
            this.getOrdersAction()
          }).catch(error => {
            this.isUpdateOfferLoading = false
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          })
        }
      })
    },

    acceptOfferForCustomer(offerId) {
      window.open(
        `https://ylomi.net/orders/${this.order.id}/offers/${offerId}/accept`,
      )
      this.$bvModal.hide('modal-action')
    },
    confimOfferPayment(offer){
      this.offerItem = offer
      this.$bvModal.hide('modal-action')
      this.$bvModal.hide('modal-offers-professionals')
      this.$bvModal.show('modal-confirm-payment')
    },
    confirmFullPayment() {
      this.$swal
      this.$swal({
        title: 'Confirmation',
        text: 'Êtes-vous sûr de vouloir confirmer le paiement de  cette offre?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.confirmOfferPaymentAction({orderId: this.$route.params.id, offerId: this.offerItem, full: 1} )
          return await this.getOrdersAction()
        },
      })
        .then(response => {
          if (response.value) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Confirmation effectué avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.$bvModal.hide('modal-confirm-payment')
            this.getOrderAction(this.$route.params.id)
            .then(response => {
              this.order = response.data
              console.log('GETORDER',this.order)
              this.applyGetServiceProfessionalsAction()
              // this.applyGetOrderOffersAction()
              
            })
            .catch(error => {
              this.isError = true
            })
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    hideModalConfirmAdvance(){
      this.advance_amount = ""
      this.$bvModal.hide('modal-advance-payment')
      this.$bvModal.show('modal-confirm-payment')
      
    },
    confirmAdvancePayment(){
      this.$bvModal.hide('modal-confirm-payment')
    },
    applyConfirmAdvancePaymentAction(){
      this.isadvanceConfirmLoading = true
      this.confirmOfferPaymentAction({orderId: this.$route.params.id, offerId: this.offerItem, full: 0, amount: this.advance_amount} )
      .then(response =>{
      this.isadvanceConfirmLoading = false
        console.log(response)
        this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Confirmation effectué avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.hideModalConfirmAdvance()
            this.getOrderAction(this.$route.params.id)
            .then(response => {
              this.order = response.data
              console.log('GETORDER',this.order)
              this.applyGetServiceProfessionalsAction()
              // this.applyGetOrderOffersAction()
              
            })
            .catch(error => {
              this.isError = true
            })
            this.getOrdersAction()

          }
          
        )
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    
    async showWorksPicture(serviceName, clientName, pictures) {
      this.serviceName = serviceName
      this.clientName = clientName
      await this.applyGetS3FileTemporaryUrlAction(pictures)
    },

    applyGetS3FileTemporaryUrlAction(files) {
      if (files != null) {
        this.pictures = []
        files.forEach(element => {
          this.getS3FileTemporaryUrlAction(element)
            .then(response => {
              this.pictures.push(response.fileUrl)
            })
            .catch(error => {
              console.log(error)
              this.pictures = -1
            })
        })
      } else {
        this.pictures = files
      }
    },

    applyGetServiceProfessionalsAction() {
      this.getServiceProfessionalsAction(this.order.service.id).then(
        response => {
          if (response.data.length === 0) {
            this.proOptions.push({
              value: '',
              disabled: true,
              text: "Vous n'avez pas de professionel pour ce service",
            })
          } else {
            response.data.forEach(element => {
              this.proOptions.push(element)
            })
          }
        },
      )
    },

    applyGetOrderOffersAction() {
      this.isOffersLoading = true
      this.getOrderOffersAction(this.order.id)
        .then(response => {
          this.offers = response.data
          // console.log('Liste des offres', this.offers)
          this.isOffersLoading = false
        })
        .catch(error => {
          this.isOffersLoading = false
        })
    },

    applyDeleteOrderAction() {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cette commande?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteOrderAction(this.order.id)
        
          return await this.getOrdersAction()
        },
      })
        .then(response => {
          if (response.value) {
            this.$router.push({ name: 'admin-orders-punctual' })
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Commande supprimée',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyDeleteOrderOfferAction(id) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cette offre?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteOrderOfferAction(id)
          await this.getOrdersAction()
          // eslint-disable-next-line no-return-await
          return  this.applyGetOrderOffersAction()
        },
      })
        .then(response => {
          if (response.value) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Offre supprimée',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.$bvModal.hide('modal-action')
            // this.offers = response.value
            console.log("count", this.order.offers.length)
            if(this.order.offers.length !== 1){
              this.$bvModal.show('modal-offers-professionals')
            }
            else{
              this.$bvModal.hide('modal-offers-professionals')
            }
            this.getOrderAction(this.$route.params.id)
            .then(response => {
              this.order = response.data
              console.log('GETORDER',this.order)
              this.applyGetServiceProfessionalsAction()
              // this.applyGetOrderOffersAction()
              
            })
            .catch(error => {
              this.isError = true
            })
            // this.applyGetOrders()
            
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyMakeOfferAction() {
      this.$refs.newOfferForm.validate().then(success => {
        if (success) {
          this.isMakingOfferLoading = true
          this.payload.id = this.order.id
          this.makeOfferAction(this.payload)
            .then(() => {
              this.getOrderAction(this.order.id).then(async response => {
                this.order = response.data
                this.isMakingOfferLoading = false
                await this.applyGetOrderOffersAction()
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: 'Offre proposée avec succès',
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )

                this.hideModal()

                this.$bvModal.show('modal-offers-professionals')
              })
              this.getOrdersAction()
            })
            .catch(error => {
              console.log(error)
              this.isMakingOfferLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      })
    },

    putPunctualOrdersInStoreAction(order) {
      this.$store.commit('orders/SET_ORDER', order)
      this.$router.push({
        name: 'admin-orders-punctual-details',
        params: { id: this.$route.params.id },
      })
    },

    applyMakeOrderAsFinished() {
      this.$swal
      .fire({
        title: 'Marquer comme terminée',
        text: 'Êtes-vous sûr de vouloir marquer cette commande comme terminée',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.finishOrderAction(this.$route.params.id)
        .then((response) => {})
              .catch((error) => {
                this.$swal.showValidationMessage(`${error.response.data.message}`);
                this.order = response.data
              }),
      })
        .then(response => {
          if (response.value) {
            
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Commande marquée comme Terminée',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
          this.getOrderAction(this.$route.params.id)
            .then(response => {
              this.order = response.data
              console.log('GETORDER',this.order)
              this.applyGetServiceProfessionalsAction()
              // this.applyGetOrderOffersAction()
              
            })
          this.putPunctualOrdersInStoreAction(this.order)
          this.getOrdersAction()
          
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    resolveOfferStatusVariant(offer) {
      if(offer.is_rejected == true) return 'danger'
      if (this.order.accepted_offer_id != null) {
        if (offer.id === this.order.accepted_offer_id) return 'success'
        return 'info'
      }
      
      return 'info'
    },

    resolveOrderStatusVariant: (status, type) => {
      if (status === 1) return 'warning'
      if (status === 0) return 'danger'
      if (status === 2 && type == 1) return 'info'
      if (status === 3) return 'success'
      return 'white'
    },

    resolveStatusText: (status, type )=> {
      if (status === 1) return 'Offre proposée'
      if (status === 0) return 'En attente'
      if (status === 2 && type === 1) return 'Avance payée'
      if (status === 2 && type === 2) return 'Payée'
    
      return "Terminée"
    },

    hideModalUpdateOffer() {
      this.$bvModal.hide('modal-modify-offer')
      this.updatePayload = {
        price: '',
        professional_id: '',
      }
    },

    hideModal() {
      this.$bvModal.hide('modal-new-offer')
      this.payload = {
        price: '',
        description: '',
        professional_id: '',
      }
    },
  },
}
</script>

<style>
.itemActions :hover {
  background: transparent !important;
  color: white !important;
}
.vs__selected-options :hover {
  background: transparent !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/pages/app-invoice.scss";

// Invoice Specific Styles
.invoice-preview-wrapper {
  .row.invoice-preview {
    .col-md-8 {
      max-width: 100%;
      flex-grow: 1;
    }

    .invoice-preview-card {
      .card-body:nth-of-type(2) {
        .row {
          > .col-12 {
            max-width: 50% !important;
          }

          .col-12:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  // Action Right Col
  .invoice-actions {
    display: none;
  }
}
</style>
